import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const BasicHeader = () => {
  return (
    <div className='fixed left-0 top-0 right-0'>
      <div className='flex justify-between p-4'>
        <div className=''>
          <img src="/GuessticleLogo-Wide-Black.svg" style={{width: '8rem'}} />
        </div>

        <div className=''>
        </div>
      </div>
    </div>
  )
}

export default BasicHeader; 