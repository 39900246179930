import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { RootState } from '../../app/store';

import {
  Player,
  Letter,
  LetterGuess, 
  LetterPlacementAccuracy,
  GameBoard,
  GuessData,
} from '../../types/_base';

import {
  setBoards,
  getGuesses,
  getCurrentPlayer,
  getPositionedLetters,
  getPresentLetters,
 } from '../../reducers/gameSharedSlice';

const SecretWordWidget = (props: { secretWord: string | null}) => {
  let content: JSX.Element;
  let style: React.CSSProperties = {};

  if (props.secretWord === null) {
    content = <>?????</>
    style = {backgroundColor: '#c2c9d1'};
  } else {
    content = <>&ldquo;{props.secretWord}&rdquo;</>
    style = {backgroundColor: '#cbc6c3'};
  }
  style.letterSpacing = '2px';

  return (
    <div className='text-sm px-2 py-2 uppercase shadow-inner' style={style}>
      {content}
    </div>
  )
}

const BoardHeader = (props: { player: Player, isOwnBoard: boolean, revealedWord: string | null }) => {
  const selfSecretWord = useAppSelector(state => state.gamePrivate?.secretWord?.set);
  const { player, isOwnBoard, revealedWord } = props;
  const secretWord: string | null = isOwnBoard ? selfSecretWord : revealedWord;
  return (
    <div className="mb-4">
      <h3 className="uppercase mb-2" style={{letterSpacing: '3px'}}>
        {player.displayName}
      </h3>
      <div className='px-8'>
        <SecretWordWidget secretWord={secretWord} />
      </div>
    </div>
  )
}

const PlayerStatus = (props: { player: Player, isOwnBoard: boolean, turnEliminated: number | null }) => {
  const { player, isOwnBoard, turnEliminated } = props;
  
  const selfSecretWord = useAppSelector(state => state.gamePrivate?.secretWord?.set);
  const playerWhoseTurnItIs = useAppSelector(getCurrentPlayer)
 
  const secretWord: string | null = props.isOwnBoard ? selfSecretWord : null;
  const playerName = isOwnBoard ? 'You' : player.displayName; 
  const beVerb = isOwnBoard ? 'were' : 'was';
  
  const isThisPlayersTurn = playerWhoseTurnItIs?.id === player.id;
  const isOwnTurn = isThisPlayersTurn && isOwnBoard;
  // const cssClass = isOwnTurn ? "bg-green-100 text-green-4" : "bg-gray-14";
  // const turnText = isOwnTurn ? "It's your turn" : `It's ${props.player.displayName}'s turn`;

  let displayText: string = '';
  let cssClasses: string = '';
  let styles: React.CSSProperties = {};
  if (turnEliminated) {
    displayText = `${playerName} ${beVerb} eliminated on turn ${(turnEliminated + 1)}`;
    cssClasses = 'text-gray-500';
    styles = { backgroundColor: '#848d98', color: '#f9fcff' };
    // styles = { backgroundColor: '#ff9d71', color: '#6a2506' };
  } else if (isThisPlayersTurn) {
    cssClasses = isOwnTurn ? "bg-green-80 text-green-4" : "bg-gray-14";
    displayText = isOwnTurn ? "It's your turn" : `It's ${props.player.displayName}'s turn`;
    styles = isOwnTurn ? { backgroundColor: '#51c68e' } : { backgroundColor: '#dcdcdc' }; // NOTE this is a nice green
  } else {
    cssClasses = "bg-gray-14";
    displayText = isOwnBoard ? 'You' : '';
    styles = isOwnBoard ? { backgroundColor: 'rgb(228 223 219)' } : { backgroundColor: '#dad6d3' };
  }
  return (
    <div className={`h-10 text-center py-2 rounded-t-lg ${cssClasses}`} style={styles}>
      {displayText}
    </div>
  )
}

const Cell = (props: LetterGuess) => {
  return (
    <td className={props.accuracy}>
      {/* <div className="uppercase text-md md:text-lg xl:text-3xl"> */}
      <div className="letter tile">
        {props.letter}
      </div>
    </td>
  )
}

const Row = (props: { guess: LetterGuess[] }) => {
  const guessedLetters = props.guess;
  
  const cellCount = 5;
  const guessedLetterCount = guessedLetters.length;
  let emptyCellCount = cellCount - guessedLetterCount;
  if (emptyCellCount < 0) { emptyCellCount = 0; }

  const emptyCells: LetterGuess[] = [...Array(emptyCellCount)].map((x, i) => { 
    return { letter: '', accuracy: 'notGuessedYet' }
  });
  const cells: LetterGuess[] = [...guessedLetters, ...emptyCells] ;
  
  return (
    <tr>
      {cells.map((letterGuess, i) => { 
        return <Cell key={i} letter={letterGuess.letter} accuracy={letterGuess.accuracy} /> 
      })}
    </tr>
  )
}

interface GameBoardPanelProps {
  guesses: GuessData;
  board: GameBoard;
  index: number | undefined;
}

export const GameBoardPanel = (props: GameBoardPanelProps) => {
  const pastGuesses = props.guesses.pastGuesses;
  const nextGuess = props.guesses.nextGuess;
  const turnEliminated = props.board.turnEliminated;
  
  const playerId = props.board.playerId;
 
  // Self
  const privateData = useAppSelector(state => state.gamePrivate);
  const selfPlayer = useAppSelector(state => state.gamePrivate?.participant);
  const selfId = selfPlayer?.id;
  

  // Other players
  const players = useAppSelector(state => state.gameShared.players);

  const player = players.find((p: Player) => { return ( p.id === playerId)})

  const board = useAppSelector(state => state.gameShared.boards.find((b: GameBoard) => { return ( b.playerId === playerId)}));
  const { revealedWord } = board || { revealedWord: null };
  
  const playerNum = typeof(props.index) === 'number' ? (props.index + 1) : 0;
  const isOwnBoard = (selfId === playerId);

  // Empty rows
  const minRowCount = 8;
  const guessRowCount = [...pastGuesses, nextGuess].length;
  let emptyRowCount = minRowCount - guessRowCount;
  if (emptyRowCount < 0) { emptyRowCount = 2; }
  const emptyArrays = [...Array(emptyRowCount)];
  const emptyRows: LetterGuess[][] = emptyArrays.map((x, i) => { 
    return [
      {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
      {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
      {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
      {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
      {letter: '', accuracy: 'notGuessedYet'} as LetterGuess,
    ] 
  });
  
  const pastGuessesAsLetterGuesses: LetterGuess[][] = pastGuesses.map((guess, i) => {
    const guessLetters = guess.split('');
    const pls: Letter[] = board?.presentLetters[i] || [];
    let presentLetters = [...pls];
    // console.log(`guessLetters: ${guessLetters}`); 
    const lgs = guessLetters.map((l, i) => {
      const letter = l as Letter; 
      const positioned = board?.positionedLetters[i] === letter;
      const present    = presentLetters.includes(letter);
      // console.log(`testing: ${letter} | positioned: ${positioned} | present: ${present} | presentLetters: ${presentLetters}`);
      if (present) {
        presentLetters.splice(presentLetters.indexOf(letter), 1);
      }
      const accuracy: LetterPlacementAccuracy = positioned ? 'correct' : ( present ? 'wrongPosition' : 'notInWord' ); 
      const lg = { letter: letter, accuracy: accuracy } as LetterGuess;
      return lg;
    });
    return lgs;
  });

  const nextGuessAsLetterGuesses: LetterGuess[] = nextGuess.split('').map((letter) => {
    return { letter: letter, accuracy: 'notInWord' }
  });

  let rowData = [...pastGuessesAsLetterGuesses, nextGuessAsLetterGuesses, ...emptyRows];
  if (turnEliminated) {
    rowData = rowData.slice(0, (turnEliminated + 1));
  }

  if (player) {
    return (
      <div className="gameboard-panel max-w-xs mb-16 mx-auto shadow-lg rounded-lg">
        <PlayerStatus isOwnBoard={isOwnBoard} player={player} turnEliminated={turnEliminated} />      
        <div className="gameboard-content pt-4 pb-8 rounded-b-lg" style={{backgroundColor: '#dad6d3'}}>
          <BoardHeader player={player} isOwnBoard={isOwnBoard} revealedWord={revealedWord} />      

          <table className="game-board mr-8 mb-8 border-collapse">
            <tbody>
              {rowData.map((guess, i) => { return <Row key={i} guess={guess} /> })}
            </tbody>
          </table>
        </div>
      </div>
    )
  } else {
    return <div>Something went wrong!</div>
  }
}

export default GameBoardPanel;