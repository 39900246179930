import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import InfoRoute from './routes/InfoRoute'
import FullScreenRoute from './routes/FullScreenRoute';
import AppRoute from './routes/AppRoute';
import { LandingPage } from './pages/info/LandingPage';
import { GuessticleGamePage } from './pages/guessticle/GuessticleGamePage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <FullScreenRoute />,
    children: [
      { path: "/", element: <LandingPage /> },
    ],
  }, {
    path: "/play",
    element: <AppRoute />,
    children: [
      { path: "/play/:urlKey", element: <GuessticleGamePage />  },
    ]
  },
]);

export function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;