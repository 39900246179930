import { 
  createSlice, 
  createAsyncThunk,
  PayloadAction,
  current
 } from '@reduxjs/toolkit'

import { RootState } from '../app/store';
import { get } from 'http';

import { Letter, LetterPlacementAccuracy, Phase, Player, Turn, UserEvent, GameBoard, GamePhaseState } from '../types/_base';

export interface GameSharedState {
  id: string | null;
  urlKey: string | null;
  currentPhaseId: string;
  phases: Phase[]; 
  players: Player[];
  boards: GameBoard[];
  userEvents: UserEvent[]; 
}

const initialState: GameSharedState = {
  id: null,
  urlKey: null,
  currentPhaseId: '',
  phases: [],
  userEvents: [], 
  players: [],
  boards: [],
};

const gameSharedSlice = createSlice({
  name: 'gameShared',
  initialState,
  reducers: {
    updateSharedGameData: (state, action) => {
      console.log("updateSharedGameData called with: ", action.payload)
      const gameShared = action.payload as GameSharedState;
      if (gameShared.id) state.id = gameShared.id;
      if (gameShared.urlKey) state.urlKey = gameShared.urlKey;
      if (gameShared.currentPhaseId) state.currentPhaseId = gameShared.currentPhaseId;
      if (gameShared.players) state.players = gameShared.players;
      if (gameShared.boards) state.boards = gameShared.boards;
      if (gameShared.phases) state.phases = gameShared.phases;
      if (gameShared.userEvents) state.userEvents = gameShared.userEvents;
    },
    setGamePhases: (state, action) => {
      state.phases = action.payload;
    },
    setPlayers: (state, action) => {
      state.players = action.payload;
    },
    setUserEvents: (state, action) => {
      state.userEvents = action.payload;
    },
    setBoards: (state, action) => {
      state.boards = action.payload;
    },
    addPlayer: (state, action: PayloadAction<Player>) => {
      const player = action.payload as Player;
      const oldPlayers = state.players as Player[];
      state.players = [player, ...oldPlayers];
    },
    addUserEvent: (state, action: PayloadAction<UserEvent>) => {
      const event = action.payload as UserEvent;
      const oldEvents = state.userEvents as UserEvent[];
      const eventExists = oldEvents.find(e => e.id === event.id);
      if (!eventExists) {
        state.userEvents = [event, ...oldEvents];
      }
    },
  },
  // extraReducers: (builder) => {
  //   builder
  // },
})

export const getCurrentPhase = (state: RootState) => {
  const phaseId = state.gameShared.currentPhaseId;
  let curPhase: Phase | undefined | null = null;
  if (phaseId) {
    curPhase = state.gameShared.phases.find((ph: Phase) => { return ( ph.id === phaseId )})
  }
  return curPhase;
}
export const getWordGuessingPhase = (state: RootState) => {
  let curPhase: Phase | undefined | null = null;
  curPhase = state.gameShared.phases.find((ph: Phase) => { return ( ph.name === "WordGuessing" )})
  return curPhase;
}

export const getCurrentTurn = (state: RootState) => {
  let curPhase:  Phase | undefined | null = getCurrentPhase(state);
  let curTurn:   Turn  | undefined | null = null;

  if (curPhase) {
    const turnId = curPhase.currentTurnId;
    curTurn = curPhase.turns.find((t: Turn) => { return ( t.id === turnId)})
  }
  return curTurn;
}

export const getCurrentPlayer = (state: RootState) => {
  let curTurn:   Turn   | undefined | null = getCurrentTurn(state);
  let curPlayer: Player | undefined | null = null;

  if (curTurn) {
    const playerId = curTurn.playerId;
    curPlayer = state.gameShared.players.find((p: Player) => { return ( p.id === playerId)})
  }
  return curPlayer;
}

export const getGuesses = (state: RootState) => {
  // const phase = getCurrentPhase(state);
  const phase = getWordGuessingPhase(state);
  const guesses = phase?.turns
    .map((turn: Turn) => { return turn.submission })
    .filter((guess: string) => { return guess && guess !== '' });

  return guesses;
}

export const getPositionedLetters = (state: RootState, boardId: string) => {
  const board = state.gameShared.boards.find((b: GameBoard) => { return ( b.playerId === boardId)});
  return board?.positionedLetters;
}
export const getPresentLetters = (state: RootState, boardId: string) => {
  const board = state.gameShared.boards.find((b: GameBoard) => { return ( b.playerId === boardId)});
  return board?.presentLetters;
}

export const getGuessesAsLetterGuesses = (state: RootState, board: GameBoard) => {
  const phase = getCurrentPhase(state);
  const gs = phase?.turns
    .map((turn: Turn) => { return turn.submission })
    .filter((guess: string) => { return guess && guess !== '' });

  let guesses = gs?.map((guess: string) => {
    const letters = guess.split('');
    return letters.map((letter: string) => {
      return {
        letter: letter,
        accuracy: 'notInWord' as LetterPlacementAccuracy,
      }
    })
  });

  if (!guesses) { 
    guesses = [] 
  }
  
  return guesses;
}

export const {
  setGamePhases,
  setPlayers,
  addPlayer,
  setUserEvents,
  addUserEvent,
  setBoards,
  updateSharedGameData,
} = gameSharedSlice.actions

export default gameSharedSlice.reducer