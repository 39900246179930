import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import ActionCable, { Cable, Subscriptions } from '@rails/actioncable';
import { createConsumer } from "@rails/actioncable"
import { config } from '../config';

const websocketUrl = config.websocketRoot;

// Define the context type
interface ActionCableContextType {
  isConnected: boolean;
  // setChannelName: (channelName: string) => void;
  // registerMessageCallback: (callback: (message: any) => void) => void;
}

// Create the context with an initial undefined value but assert the correct type
const ActionCableContext = createContext<ActionCableContextType | undefined>(undefined);

interface ActionCableProviderProps { children: ReactNode; }

// ActionCable provider component
export const ActionCableProvider: React.FC<ActionCableProviderProps> = ({ children }) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  // const [channelName, setChannelName] = useState<string | null>(null);
 
  const [messageCallback, setMessageCallback] = useState<((message: any) => void) | null>(null);

  // useEffect(() => {
  //   if (channelName && channelName.length > 0 && messageCallback) {
  //     const consumer = createConsumer(websocketUrl);
  //     const subscription = consumer.subscriptions.create(
  //       { channel: channelName }, 
  //       { received: messageCallback}
  //     )
  //   }

  //   return () => {
  //     // TODO disconnect from the channel
  //     // ws.close();
  //   };
  // }, [channelName, messageCallback]);

  const registerMessageCallback = (callback: (message: any) => void) => {
    setMessageCallback(() => callback);
  };

  return (
    // <ActionCableContext.Provider value={{ isConnected, registerMessageCallback }}>
    <ActionCableContext.Provider value={{ isConnected }}>
      {children}
    </ActionCableContext.Provider>
  );
};

export const useActionCable = (channelName: string, urlKey: string, callback?: (message: any) => void): ActionCableContextType => {
  // const context = useContext(ActionCableContext);
  // if (context === undefined) {
  //   throw new Error('useActionCable must be used within a WebSocketProvider');
  // }
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    const consumer = createConsumer(websocketUrl);
    const subscription = consumer.subscriptions.create(
      { channel: channelName, urlKey: urlKey },
      {
        received: callback,
        connected: () => setIsConnected(true),
        disconnected: () => setIsConnected(false),
      }
    );

    return () => {
      // consumer.subscriptions.remove(subscription);
    };
  }, [channelName, callback]);

  return { isConnected };
};