import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { url } from 'inspector';
// import { LocalStorageGameSession } from '../types/_base';

interface AuthMiddlewareOptions {}

const authMiddleware = (
  baseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, {}, {}>,
  options?: AuthMiddlewareOptions
) => async (args: string | FetchArgs, api: any, extraOptions: any) => {
  console.log("hi from authMiddleware!", args, api, extraOptions);
  // Directly handle the addition of the Authorization header to FetchArgs
  
  let participantToken: string | null = null;
  
  const authToken = localStorage.getItem('authToken');
 
  const urlKey = localStorage.getItem('urlKey');
  if (urlKey) {
    participantToken = localStorage.getItem('participantToken-' + urlKey);
  } else {
    console.log("urlKey not found in localStorage")
  }
  
  console.log("authToken", authToken);
  console.log("participantToken", participantToken);
  
  if (typeof args !== 'string') {
    console.log("args is not a string");
    if (authToken) {
      args.headers = {
        ...args.headers,
        Authorization: `Bearer ${authToken}`,
      };
    }
    if (participantToken) {
      console.log("adding Participation-Token to headers");
      args.headers = {
        ...args.headers,
        "Participation-Token": `Bearer ${participantToken}`,
      };
    } else {
      console.log("NOT adding Participation-Token to headers");
    }
  } else {
    console.log("args is a string");
  }

  return baseQuery(args, api, extraOptions);
};

export default authMiddleware;