import React, { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import ActionCable, { Cable, Subscriptions } from 'actioncable';
import { subscribeToActionCable } from './WebsocketInteractions';

import { GameSharedState } from '../../reducers/gameSharedSlice';
import { getChatMessagesForGameSession } from '../../reducers/chatSlice';
import { useSubmitMessageMutation } from '../../services/gameSessionService';

import { useFetchGameSessionMessagesQuery } from '../../services/messageService'; 

import {
  UrlKey,
  UUID,
  GameChatMessage,
  ChatMessage,
  Player,
  UserEvent,
} from '../../types/_base';

const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

const playerColors = {
  red: '#f56565',
  blue: '#4299e1',
  teal: '#38b2ac',
  purple: '#9f7aea',
  pink: '#ed64a6',
  lime: '#68d391',
  gray: '#718096',
};

const additionalPlayerColors = {
  white: '#fff',
  orange: '#ed8936',
  indigo: '#667eea',
  yellow: '#ecc94b',
  black: '#1a202c',
  // cyan: '#63b3ed',
  // green: '#51c68d',
}
const assignUniqueDisplayColors = (players: Player[]): Player[] => {
  const usedColors = new Set(players.map(player => player.displayColor).filter(color => color));
  let availableColors = Object.values(playerColors).filter(color => !usedColors.has(color));

  availableColors = shuffleArray(availableColors);

  return players.map(player => {
    if (player.displayColor) {
      // If the player already has a displayColor, return the player as is
      return player;
    } else {
      // Attempt to find an unused color
      const color = availableColors.shift(); // This removes the color from availableColors
      if (color) {
        // If a color is found, return a new object with the color assigned
        return { ...player, displayColor: color };
      } else {
        // No available color (more players than colors), return the player without modification
        // In a real scenario, you might handle this differently
        return player;
      }
    }
  });
};

// function assignUniqueColors(players: Player[]): Player[] {
//   const usedColors = new Set<string>();
//   // First, populate usedColors with already assigned colors to avoid re-assigning them
//   players.forEach(player => {
//     if (player.displayColor) usedColors.add(player.displayColor);
//   });

//   // Now, assign a unique color to each player without a displayColor
//   players.forEach(player => {
//     if (!player.displayColor) {
//       // Find the first color not yet used
//       const availableColor = Object.values(playerColors).find(color => !usedColors.has(color));
//       if (availableColor) {
//         player.displayColor = availableColor; // Assign the available color
//         usedColors.add(availableColor); // Mark this color as used
//       }
//       // Note: This logic does not handle the case where there are more players than available colors
//     }
//   });

//   return players;
// }

interface EventListingProps {
	item: UserEvent | ChatMessage;
	players: Player[];
}

const EventListing = (props: EventListingProps) => {
  const item = props.item;
  let type: 'message' | 'event' = 'message';
  let gameParticipantId: UUID | null = null;
  let body: string = '';

  if ('playerId' in item) {
    type = 'event';
    gameParticipantId = item.playerId;
    body = item.desc;
  } else if ('gameParticipantId' in item) {
    type = 'message';
    gameParticipantId = item.gameParticipantId;
    body = item.content;
  }

  const player = props.players.find((p) => p.id === gameParticipantId);
  const playerName = player ? player.displayName : "sum1";
  
  const nameStyles = {
    fontSize: '50%',
    color: player?.displayColor || 'black',
  }

  return (
    <li className='leading-tight mb-3 flex flex-col'>
      <div className='' style={nameStyles}>{playerName}</div>
      <div>{body}</div>
    </li>
  )
}

const ActivityLog = (props: { userEvents: UserEvent[], messages: ChatMessage[], players: Player[] }) => {
  const content = [...props.messages, ...props.userEvents].sort((a, b) => a.createdAt - b.createdAt).reverse();
  console.log('content: ', content);
  return (
    <>
    <h3 className='mb-2'>Activity</h3>
    <div className='shadow-inner pt-4 px-3 pb-6 mb-4 overflow-y-scroll' style={{backgroundColor: '#d3cfcc'}}>
      <ul className='text-left'>
        {content.map((item, i) => { return <EventListing item={item} players={props.players} key={`event-${i}`} /> })}
      </ul>
    </div>
    </>
  )
}

const NewChatForm = (props: { urlKey: UrlKey }) => {
  const { urlKey } = props;
  
  const [
    submitMessage,
    { isLoading: isUpdating, isError: isError, isSuccess: guessSuccessful, data: guessData, error: guessError },
  ] = useSubmitMessageMutation();
  
  const [message, setMessage] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (urlKey && message) {
      submitMessage({urlKey: urlKey, content: message});
      setMessage('');
    } else {
      console.error("No urlKey or message")
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className='w-full p-2 mb-2 rounded shadow-inner'
      />
      <button type="submit" className='btn w-full p-2 rounded shadow'>Send</button>
    </form>
  )

}

export const GameSessionRightSidebar = (props: { urlKey: UrlKey, id: UUID }) => {
  const { urlKey, id } = props;
  const gameSharedState: GameSharedState = useAppSelector(state => state.gameShared);
  const messages: GameChatMessage[] = useAppSelector(state => getChatMessagesForGameSession(state, id));
  const { players, userEvents } = gameSharedState;
  
  const { data, error, isLoading } = useFetchGameSessionMessagesQuery(id);
  // console.log('data: ', data);

  const decoratedPlayers = assignUniqueDisplayColors(players);
  
  return (
    <div className="bg-gray-8 p-4 h-full rounded shadow-lg" style={{backgroundColor: '#dad6d3'}}>
      <div className='h-full flex flex-col justify-between'>
        <div>
          <div className="grow-0">
            {userEvents.length > 0 && <ActivityLog userEvents={userEvents} messages={messages} players={decoratedPlayers} />}
          </div>
        </div>
        
        <div className="mb-4 overflow-y-scroll">
          <div className='shadow-inner p-2 pb-4 mb-4 overflow-y-scroll' style={{backgroundColor: '#d3cfcc'}}>
            <h3 className='mb-2 text-sm uppercase' style={{letterSpacing: '2px'}}>
              New Message
            </h3>
            {urlKey && <NewChatForm urlKey={urlKey} />}
          </div>
          <h3 className='mb-2'>Players</h3>
          <ul className="text-left">
            {players && players.map((player, i) => { return <li key={`player-${i}`}>{player.displayName}</li> })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default GameSessionRightSidebar