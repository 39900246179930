import React, { useEffect, useState, useRef } from 'react'
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { Store } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { Link } from 'react-router-dom';

import { RootState } from '../../app/store';

import { useCreateGameMutation } from '../../services/gameSessionService';
// import { createGame } from '../../reducers/playerSlice';

import { GameSlim } from '../../types/_base';

import SloganDisplay from '../../components/landingPage/Slogans';

import { 
  popupBackgroundClasses, 
  popupForegroundClasses, 
  popupInnerClasses,
  popupForegroundStyles 
} from '../../stylesheets/cssClasses';

const RulesText = () => {
  return (
    <div className='mb-8'>
      <p className='mb-4'>
        Guessticle is a word guessing game for friends. 
      </p>
      <p className='mb-4'>
         At the start of the game, each player gets a pool of random letters. 
         From your unique pool of letters, you select a 5-letter secret word.
      </p>
      <p className='mb-4'>
        The goal of the game is to guess the secret words of the other players.
        The player whose secret word is guessed last wins the game.
      </p>
      <p className='mb-4'>
        During the process of guessing, you discover hints about which letters are in the different players' secret words.
        Every guess reveals information about every player's secret word &mdash; so guess carefully!
      </p>
    </div>
  )
}

type PopupWidgetProps = {
  close: (e: any) => void;
}

const CreateNewGamePrompt = () => {
  const [
    createGame, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useCreateGameMutation();

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('CreateNewGamePrompt clickHandler')
    e.stopPropagation();
    const thing = createGame(null);
    console.log('thing', thing);
  }

  return (
    <div>
      <div className='text-2xl font-bold mb-4'>Start a New Game</div>
      <p className='mb-4'>
        When you start a new game, you will be given a <i>sharing code</i> to send to your friends.
      </p>
      <p className='mb-8'>
        Then, when your friends are at this screen, they can click "Join a game" and use the sharing code to join your game.
      </p>
      <button className='btn relative z-40' onClick={clickHandler}>
        Start new game and get sharing code
      </button>
    </div>
  )
}

const ShareNewGamePrompt = (props: { urlKey: string }) => {
  const gameUrl = `${window.location.origin}/play/${props.urlKey}`;

  return (
    <div>
      <div className='text-2xl font-bold mb-4'>Your New Game</div>
      <p className='mb-4'>
        The sharing code for your new game is:
      </p>
      <p className='mb-4 text-2xl'>
        {props.urlKey}
      </p>
      <p className='mb-4'>
        Send this code to your friends so they can join your game.
      </p>
      <p className='mb-4'>
        Or, you can send them this link:
      </p>
      <div className='mb-8 text-xl'>
        {gameUrl}
      </div>

      <a className='btn' href={gameUrl} target='_blank'>
        Go to your game
      </a>
    </div>
  )
}

export const StartNewGameWidget = (props: PopupWidgetProps) => {
  const dispatch = useAppDispatch();
  const bgRef = useRef<HTMLDivElement>(null);
  const newGame: GameSlim | null = useAppSelector((state: RootState) => state.gamePrivate.nextGame);
  
  const sharingCode = newGame ? newGame.urlKey : null;

  const closePopup = (e: any) => {
    console.log(bgRef.current, e.target);
    if (bgRef.current === e.target) {
      props.close(e);
    }
  }

  return (
    <div className={popupBackgroundClasses} onClick={closePopup} ref={bgRef}>
      <div className={popupForegroundClasses} style={popupForegroundStyles}>
        {sharingCode ? <ShareNewGamePrompt urlKey={sharingCode} /> : <CreateNewGamePrompt />}
      </div>
    </div>
  )
}

export const JoinGameWidget = (props: PopupWidgetProps) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const [urlKey, setUrlKey] = useState<string>('');

  const urlKeyPattern = /^[a-zA-Z0-9]{4}$/;
  const urlKeyInvalid = !(urlKeyPattern.test(urlKey)); 

  const closePopup = (e: any) => {
    console.log(bgRef.current, e.target);
    if (bgRef.current === e.target) {
      props.close(e);
    }
  }

  const handleUrlKeyChange = (e: any) => {
    setUrlKey(e.target.value);
  }
  
  return (
    <div className={popupBackgroundClasses} onClick={closePopup} ref={bgRef}>
      <div className={popupForegroundClasses} style={popupForegroundStyles}>
        <div className='text-2xl font-bold mb-4'>Join an existing game</div>
        <p>Enter the 4-digit code for a game you'd like to join</p>
        <input 
          onChange={handleUrlKeyChange}
          className='mt-4 mb-8 p-1 text-center text-5xl border border-gray-50 rounded-sm w-full uppercase'
          style={{letterSpacing: '0.25rem'}}
          type="text" 
          name="urlKey" 
          placeholder="code" 
          required
        />

        {!urlKeyInvalid && <a className='btn mb-8' href={`/play/${urlKey}`} target='_blank'><>Join game &ldquo;<span className='uppercase'>{urlKey}</span>&rdquo;</></a>}
      </div>
    </div>
  )
}

export const RulesWidget = (props: PopupWidgetProps) => {
  const bgRef = useRef<HTMLDivElement>(null);

  const closePopup = (e: any) => {
    console.log(bgRef.current, e.target);
    if (bgRef.current === e.target) {
      props.close(e);
    }
  }
  
  return (
    <div className={popupBackgroundClasses} onClick={closePopup} ref={bgRef}>
      <div className={popupForegroundClasses} style={popupForegroundStyles}>
        <div className={popupInnerClasses}>
          <div className='text-2xl font-bold mb-4'>How to play Guessticle</div>
          <RulesText />
          <button className='btn' onClick={props.close}>
            Done reading
          </button>
        </div>
      </div>
    </div>
  )
}

export const LandingPageButtons= () => {
  const [popupShown, setPopupShown] = useState('none');

  const closePopUp = () => {
    setPopupShown('none');
  }
  const showJoinGameWidget = () => {
    setPopupShown('joinGame');
  }
  const showStartNewGameWidget = () => {
    setPopupShown('startNewGame');
  }
  const showReadRulesWidget = () => {
    setPopupShown('readRules');
  }

  return (
    <div className='flex justify-center flex-col md:grid grid-cols-3 mx-auto max-w-4xl' style={{width: '56rem'}}>
      <div className='my-2 mx-4'>
        <button className='btn w-full' onClick={showJoinGameWidget}>
          Join a Game
        </button>
      </div>
      
      <div className='my-2 mx-4'>
        <button className='btn w-full' onClick={showStartNewGameWidget}>
          Start a Game
        </button>
      </div>
      
      <div className='my-2 mx-4'>
        <button className='btn w-full' onClick={showReadRulesWidget}>
          Learn the Rules
        </button>
      </div>

      {popupShown === 'joinGame'      && <JoinGameWidget close={closePopUp} />     }
      {popupShown === 'startNewGame'  && <StartNewGameWidget close={closePopUp} /> }
      {popupShown === 'readRules'     && <RulesWidget close={closePopUp} />        }
    </div>
  )
}

export const LandingPage = () => {
  return (
    <div className="p-3 flex flex-col justify-center w-full" style={{height: '100vh'}}>
      <div className="mb-4 flex justify-center">
        <img src="/GuessticleLogo.svg" style={{maxHeight: '75vh'}} />
      </div>

      <SloganDisplay />

      <LandingPageButtons />
    </div>
  )
}

