import React, { useState, useRef } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useJoinGameMutation, useStartGameMutation } from '../../services/gameSessionService';
import { UrlKey } from '../../types/_base';

import { popupBackgroundClasses, popupForegroundClasses, popupInnerClasses, popupForegroundStyles } from '../../stylesheets/cssClasses';

type PopupWidgetProps = {
  urlKey: UrlKey;
  close: (e: any) => void;
}

export const JoinTheGameForm = (props: PopupWidgetProps) => {
  const [
    joinGame, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useJoinGameMutation();

  const [ displayName, setDisplayName ] = useState<string>('');

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('JoinTheGameForm submitHandler')
    e.stopPropagation();
    if (displayName.length === 0) {
      return;
    }
    const res = joinGame({ urlKey: props.urlKey, displayName: displayName });
    console.log('JoinTheGameForm | res: ', res);
  }
  const bgRef = useRef<HTMLDivElement>(null);

  const closePopup = (e: any) => {
    console.log(bgRef.current, e.target);
    if (bgRef.current === e.target) {
      props.close(e);
    }
  }

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(e.target.value);
  }

  return (
    <div className={popupBackgroundClasses} onClick={closePopup} ref={bgRef}>
      <div className={popupForegroundClasses} style={popupForegroundStyles}>
          <h1 className="font-bold text-4xl">Join the game</h1>
          <p className="my-5">Choose your display name</p>
          <div className="mb-5">
            <input 
              className="block shadow rounded-md border border-gray-200 outline-none px-3 py-2 mt-2 w-full"
              placeholder='Display name' 
              type="text"
              onChange={changeHandler} 
            />
          </div>

          <div className="inline">
            <button className='btn relative z-40' onClick={submitHandler}>
              Join game as "{displayName}"
            </button>
            {/* <input 
              type="submit" 
              name="commit" 
              value="Join game" 
              className="btn rounded-lg py-3 px-5 bg-blue-600 text-white inline-block font-medium cursor-pointer" 
              data-disable-with="Join game"
            /> */}
          </div>
      </div>
    </div>
  )
}

const JoinTheGameWidget = (props: { urlKey: string }) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const clickHandler = (e: any) => {
    e.preventDefault();
    setShowForm(true);
  }

  if (showForm) {
    return (
      <JoinTheGameForm urlKey={props.urlKey} close={() => setShowForm(false)} />
    )
  } else {
    return (
      <div className="max-w-3xl mx-auto">
        <div className="mb-8">
          <h1 className="text-xl">Welcome!</h1>
          <p>This game hasn't started yet.</p>
        </div>
        
          <div>
            <a className="btn" onClick={clickHandler}>Click here to join the game</a>
          </div>
      </div>
    )
  }
}

const StartTheGameButton = (props: { urlKey: string }) => {
  const dispatch = useAppDispatch();
  const [ startGame, { isLoading: isUpdating } ] = useStartGameMutation();
  
  const ch = (e: any) => {
    e.preventDefault();
    startGame({urlKey: props.urlKey});
  }

  return (
    <button className="btn" onClick={ch}>
      Start the game {isUpdating && `isUpdating: ` + isUpdating }
    </button>
  )
}

const MaybeStartTheGameWidget = (props: { urlKey: string }) => {
  return (
    <div>
      <p>You can see the other players in the sidebar on the right.</p>
      <p className="mb-4">When everyone is here, you can</p>
      <div>
        <StartTheGameButton urlKey={props.urlKey} />    
      </div>
    </div>
  )
}

export const PreGame = (props: { urlKey: string}) => {
  // const gameShared = useAppSelector(state => state.gameShared);
  // const { urlKey } = gameShared;
  
  const gamePrivate = useAppSelector(state => state.gamePrivate);
  const { participant } = gamePrivate;
  
  if (participant && participant.id) {
    return (<MaybeStartTheGameWidget urlKey={props.urlKey} />)
  } else {
    return (<JoinTheGameWidget urlKey={props.urlKey} />)
  }
}