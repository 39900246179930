import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import authMiddleware from '../middlewares/authMiddleware';
import { UrlKey, GameChatMessage, ChatMessage } from '../types/_base';
import { GameSharedState, updateSharedGameData } from '../reducers/gameSharedSlice';
import { GamePrivateState, updatePrivateGameData, setNextGame, setNextGuess, setNextGuessSubmissionStatus } from '../reducers/gamePrivateSlice';
import { appendGameChatMessage } from '../reducers/chatSlice';
import { act } from 'react-dom/test-utils';

import { GameSessionServerResponse, SingleMessageServerResponse, ApiError } from '../types/dataTransfer';

// Set up base query with auth middleware
// const gameSessionApiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v0/game_sessions`;
const gameSessionApiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v1/game_sessions`;
const baseQuery = fetchBaseQuery({ baseUrl: gameSessionApiEndpoint });
const baseQueryWithAuth = authMiddleware(baseQuery);

export const gameSessionApi = createApi({
  reducerPath: 'gameSessionApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchGame: builder.query<GameSessionServerResponse, string>({
      query: (id) => ({ // Query needs to be this more complex object to work with the middleware
        url: `${id}`,
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { sharedState, privateState, id, urlKey }} = await queryFulfilled;
          console.log("sharedState", sharedState);
          console.log("privateState", privateState);
          dispatch(updateSharedGameData({ ...sharedState, id, urlKey }));
          if (privateState && Object.keys(privateState).length > 0) { 
            dispatch(updatePrivateGameData({ ...privateState, id, urlKey }));  
          } else {
            console.log("WARNING: Expected privateData after joining game; found none.");
          }
        } catch (error) {
          // Handle error
          console.log("error", error);
        }
      }
    }),
    // createGame: builder.mutation<{gameSession: GamePhaseState}, null>({
    createGame: builder.mutation<GameSessionServerResponse, null>({
      query: () => ({
        url: ``,
        method: 'POST',
        cache: "no-cache",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { id, urlKey }} = await queryFulfilled;
          console.log("gameSessionData", {id, urlKey});
          dispatch(setNextGame({id, urlKey}));
        } catch (error) {
          // Handle error
        }
      },
    }),
    joinGame: builder.mutation<GameSessionServerResponse, {urlKey: UrlKey, displayName: string}>({
      query: ({ urlKey, displayName }) => ({
        url: `/${urlKey}/join`,
        method: 'POST',
        body: {
          game_participant: { url_key: urlKey, display_name: displayName }
        },
        cache: "no-cache",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { sharedState, privateState, id, urlKey }} = await queryFulfilled;
          console.log("sharedState", sharedState);
          console.log("privateState", privateState);
          dispatch(updateSharedGameData({ ...sharedState, id, urlKey }));
          if (privateState && Object.keys(privateState).length > 0) { 
            dispatch(updatePrivateGameData({ ...privateState, id, urlKey }));  
          } else {
            console.log("WARNING: Expected privateData after joining game; found none.");
          }
        } catch (error) {
          // Handle error
        }
      },
    }),
    startGame: builder.mutation<GameSessionServerResponse, {urlKey: UrlKey}>({
      query: ({ urlKey }) => ({
        url: `/${urlKey}/start`,
        method: 'POST',
        cache: "no-cache",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { sharedState, privateState, id, urlKey }} = await queryFulfilled;
          console.log("sharedState", sharedState);
          console.log("privateState", privateState);
          if (sharedState && Object.keys(sharedState).length > 0) { 
            dispatch(updateSharedGameData({ ...sharedState, id, urlKey }));
          } 
          if (privateState && Object.keys(privateState).length > 0) { 
            dispatch(updatePrivateGameData({ ...privateState, id, urlKey }));  
          } 
        } catch (error) {
          // Handle error
        }
      },
    }),
    submitWordSelection: builder.mutation<GameSessionServerResponse, {urlKey: UrlKey, word: string}>({
      query: ({ urlKey, word }) => ({
        url: `/${urlKey}/word_selection`,
        method: 'POST',
        body: {
          game_participant: { word_str: word },
        },
        cache: "no-cache",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { sharedState, privateState, id, urlKey }} = await queryFulfilled;
          dispatch(updateSharedGameData({ ...sharedState, id, urlKey }));
          if (privateState && Object.keys(privateState).length > 0) { 
            dispatch(updatePrivateGameData({ ...privateState, id, urlKey }));  
          } 
        } catch (error) {
          // Handle error
        }
      },
    }),
    submitGuess: builder.mutation<GameSessionServerResponse, {urlKey: UrlKey, word: string}>({
      query: ({ urlKey, word }) => ({
        url: `/${urlKey}/turn`,
        method: 'POST',
        body: {
          game_turn: { submission_str: word },
        },
        cache: "no-cache",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { sharedState, privateState, id, urlKey }} = await queryFulfilled;
          dispatch(updateSharedGameData({ ...sharedState, id, urlKey }));
          dispatch(setNextGuess({word: '', serverMessage: ''}));
          if (privateState && Object.keys(privateState).length > 0) { 
            dispatch(updatePrivateGameData({ ...privateState, id, urlKey }));  
          } 
        } catch (error: unknown) {
          if (typeof error === "object" && error !== null && "error" in error) {
            const serverError = error as ApiError;
            console.log(serverError.error.data.errors);
            dispatch(setNextGuess({word: '', serverMessage: serverError.error.data.errors}));
            dispatch(setNextGuessSubmissionStatus('error'));
          }
        // } catch (error) {
          // Handle error
        }
      },
    }),
    submitMessage: builder.mutation<SingleMessageServerResponse, {urlKey: UrlKey, content: string}>({
      query: ({ urlKey, content }) => ({
        url: `/${urlKey}/message`,
        method: 'POST',
        body: {
          chat_message: { content: content },
        },
        cache: "no-cache",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          // const { data: { message as GameChatMessage }} = await queryFulfilled;
          const { data } = await queryFulfilled;
          const message = data.message as GameChatMessage;
          dispatch(appendGameChatMessage({ gameSessionId: message.gameSessionId, message }));
        } catch (error: unknown) {
          if (typeof error === "object" && error !== null && "error" in error) {
            const serverError = error as ApiError;
            console.log(serverError.error.data.errors);
            dispatch(setNextGuess({word: '', serverMessage: serverError.error.data.errors}));
            dispatch(setNextGuessSubmissionStatus('error'));
          }
        // } catch (error) {
          // Handle error
        }
      },
    }),
  }),
});


export const { 
  // useGetQuery, 
  useFetchGameQuery, 
  useCreateGameMutation,
  useJoinGameMutation,
  useStartGameMutation,
  useSubmitWordSelectionMutation,
  useSubmitGuessMutation,
  useSubmitMessageMutation 
} = gameSessionApi 