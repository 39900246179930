import React, { useEffect, useState, useCallback, useRef } from 'react'
import { useParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks'

import { useFetchGameQuery, useCreateGameMutation } from '../../services/gameSessionService';
import { useActionCable } from '../../hooks/ActionCableContext';

import { UrlKey, PhaseName } from '../../types/_base';

import { PreGame } from '../../features/guessticle/PreGame';
import { WordSelection } from '../../features/guessticle/WordSelection';
import { WordGuessing } from '../../features/guessticle/WordGuessing';
import { PostGame } from '../../features/guessticle/PostGame';

import { updateSharedGameData, addPlayer, addUserEvent, setBoards, setGamePhases } from '../../reducers/gameSharedSlice';
import { appendGameChatMessage } from '../../reducers/chatSlice';

const phaseComponents: Record<PhaseName, React.ComponentType<{urlKey: string}>> = {
  PreGame: PreGame,
  WordSelection: WordSelection,
  WordGuessing: WordGuessing,
  PostGame: PostGame,
};

export const GuessticleGamePage = () => {
  const dispatch = useAppDispatch();

  let urlKey = useParams<{urlKey: string}>().urlKey as UrlKey;
  const gameSharedState = useAppSelector(state => state.gameShared);
  const { currentPhaseId, phases } = gameSharedState;
  const { data, error, isLoading } = useFetchGameQuery(urlKey);
  
  const [currentPhaseName, setCurrentPhaseName] = useState<string | null>(null);
  
  const channelName = 'GameEventsChannel';

  const handleReceivedMessage = useCallback((msg: any) => {
    console.log("handleReceivedMessage called with: ")
    console.log(msg)
    // let data = {};
    
    if (msg.sharedState) {
      // if (msg.id)      data.id = msg.id;
      // if (msg.urlKey)  data.urlKey = msg.urlKey;
      dispatch(updateSharedGameData(msg.sharedState));
    } else if (msg.gameEvent) {
      console.log("Adding gameEvent: ", msg)
      dispatch(addUserEvent(msg.gameEvent));
    } else if (msg.message) {
      console.log("Adding message: ", msg)
      const message = msg.message;
      dispatch(appendGameChatMessage({ gameSessionId: message.gameSessionId, message }));
    } else {
      console.log("Got something via websocket that I don't know how to handle: ");
      console.log(msg);
    }
  }, []); 
  const { isConnected } = useActionCable(channelName, urlKey, handleReceivedMessage);
  
  useEffect(() => {
    if (currentPhaseId && phases) {
      const currentPhase = phases.find(phase => phase.id === currentPhaseId);
      if (!currentPhase) {
        console.error("No current phase found");
        setCurrentPhaseName(null);
      } else {
        setCurrentPhaseName(currentPhase.name);
      }
    }
  }, [currentPhaseId, phases]); // Include phases in the dependency array if it's expected to change

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error...</div>;
  } else if (!currentPhaseName) {
    return <div>Loading or invalid phase...</div>;
  } else {
    const PhaseComponent = phaseComponents[currentPhaseName as PhaseName] || null;
    return PhaseComponent ? <PhaseComponent urlKey={urlKey} /> : <div>Invalid phase component</div>;
  }
};

export default GuessticleGamePage;