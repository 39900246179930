import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { 
  appendLetterToSecretWordSelection,
  removeLetterFromSecretWordSelection,
  setSecretWordSelection,
  shuffleAvailableLetters,
  setSelectedWord,
} from '../../reducers/gamePrivateSlice'

import { useSubmitWordSelectionMutation } from '../../services/gameSessionService';

const IndividualLetterDisplay = (props: { letter: string, isSelected: boolean }) => {
	const dispatch = useAppDispatch();
  const letter: string = props.letter;
  const isSelected: boolean = props.isSelected;
	
  const onClick = (e: any) => {
    e.preventDefault();
    console.log(`LetterKey onClick: ${props.letter}`);
    if (isSelected) {
      dispatch(removeLetterFromSecretWordSelection({ letter: letter }))
    } else {
      dispatch(appendLetterToSecretWordSelection(letter));
    }
	}
  
  return (
    <div className={`letter tile ${isSelected ? 'selected' : 'not-selected'}`} onClick={onClick}>
      {letter}
    </div>
  )
}

const LetterPoolDisplay = () => {
  const dispatch = useAppDispatch();
  
  const selectedLettersAsString: string = useAppSelector(state => state.gamePrivate.secretWord.selected) || '';
  const selectedLetters: string[] = selectedLettersAsString.split('');
  
  const availLetterStringAsString: string = useAppSelector(state => state.gamePrivate.letterPool.availableLetters);
  const availableLetters: string[] = availLetterStringAsString.split('');

  const assignLetterSelection = (availableLetters: string[], selectedLetters: string[]): { letter: string, isSelected: boolean }[] => {
    const lws = availableLetters.map((letter) => {
      let isSelected: boolean = selectedLetters.includes(letter);
      if (isSelected) {
        const index = selectedLetters.indexOf(letter);
        selectedLetters.splice(index, 1);
      }
      return { letter: letter, isSelected: isSelected };
    })
    return lws;
  }
  const lettersWithSelection = assignLetterSelection(availableLetters, selectedLetters);
  
  const triggerShuffle = () => {
    dispatch(shuffleAvailableLetters());
  }
  
  return (
    <div>
      <div className="p-4 mx-auto max-w-md rounded panel" style={{backgroundColor: '#dad6d3'}}>
        <h2 className="mb-2">Your letters</h2>
        <table className="game-board" style={{width: '21rem'}}>
          <tbody>
            <tr>
              { lettersWithSelection.map(({ letter, isSelected }, i) => { 
                return (
                  <td key={`letter-${i}`}>
                    <IndividualLetterDisplay letter={letter} isSelected={isSelected} />
                  </td> 
                )
              })}
            </tr>
          </tbody>
        </table>
        <button 
          className='btn tiny' 
          style={{backgroundColor: '#c7ced1'}}
          onClick={triggerShuffle}
        >
          Shuffle
        </button>
      </div>
      <div className='text-sm my-4'>
        <p>
          These are your unique random letters. 
        </p>
        <p>
          The other players have different random letters.
        </p>
        <p>
          The word you select must be made up of these letters.
        </p>
      </div>
    </div>
  )
}

const WordForm = (props: { urlKey: string }) => {
  const dispatch = useAppDispatch();
  
  const word: string = useAppSelector(state => state.gamePrivate.secretWord.selected) || '';
  
  const [
    submitWordSelection, // This is the mutation trigger
    { isLoading: isUpdating }, // This is the destructured mutation result
  ] = useSubmitWordSelectionMutation();

  const defaultButtonText = 'Select a word to continue';
  const [buttonText, setButtonText] = useState<string>(defaultButtonText);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
  const [renderButton, setRenderButton] = useState<boolean>(true);
  const [msgCss, setMsgCss] = useState<string>('');
  
  const serverMessage: string | null = useAppSelector(state => state.gamePrivate.secretWord.serverMessage)

  const handleWordChange = (e: any) => {
    dispatch(setSelectedWord(e.target.value));
  }
 
  // TODO - do this through the RTK query hooks
  // useEffect(() => {
  //   if (apiState === 'success') {
  //     setRenderButton(false);
  //     setMsgCss('bg-green-100');
  //   } else {
  //     setRenderButton(true);
  //     setMsgCss('bg-red-100');
  //   } 
  // }, [apiState]);

  useEffect(() => {
    if (word.length === 5) {
      setCanSubmit(true);
      setButtonText(`Play with "${word}"`);
      setButtonDisabled(false);
    } else {
      setCanSubmit(false);
      setButtonDisabled(true);
      setButtonText(defaultButtonText);
    } 
  }, [word]);

  const submitWord: React.FormEventHandler = (e) => {
		e.preventDefault()
    console.log("submitting word selection")
    if (canSubmit) {
      submitWordSelection({
        word: word,
        urlKey: props.urlKey,
      });
    }
	}

  return (
    <div className="py-4">
      <h2>Your secret word</h2>
      <form onSubmit={submitWord}>
				<input 
          onChange={handleWordChange}
          value={word}
          className='mt-4 mb-8 p-1 text-center text-5xl border border-gray-50 rounded-sm w-full uppercase'
          type="text" 
          name="secretWord" 
          placeholder="adieu"
          autoComplete="off"
          required
        ></input>
        {serverMessage && <div className={`${msgCss} py-2 text-gray-0`}>{serverMessage}</div>}
				{renderButton && 
          <input 
            type="submit"
            disabled={buttonDisabled} 
            value={buttonText} 
            className="btn w-full" 
            style={{margin: '1rem auto'}}
          ></input>
        }
			</form>
    </div>
  )
}

function shuffleArray<T>(inputArray: T[]): T[] {
  const array = [...inputArray];
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export const LockedInWordSelection = () => {
  const secretWord: string = useAppSelector(state => state.gamePrivate.secretWord.set) || '';

  return (
    <div className="p-4 mx-auto max-w-md rounded panel" style={{backgroundColor: '#dad6d3'}}>
      <h2 className="mb-2">Your secret word</h2>
      <div className="shadow-inner pt-4 px-3 pb-6 mb-4 overflow-y-scroll" style={{backgroundColor: 'rgb(211, 207, 204)'}}>
        <p className='uppercase' style={{fontSize: '3rem', letterSpacing: '4px'}}>
          {secretWord}
        </p>
      </div>
    </div>
  )
}

export const WordSelection = (props: { urlKey: string}) => {
  const secretWord: string = useAppSelector(state => state.gamePrivate.secretWord.set) || '';
  const wordIsSet: boolean = secretWord.length > 0;

  if (wordIsSet) {
    return (
      <div className='max-w-md mx-auto'>
        <LockedInWordSelection />
        <p className='mt-8'>
          Waiting for other players to select their words...
        </p>
      </div>
    )
  } else {
    return (
      <div className='max-w-md mx-auto'>
        <LetterPoolDisplay />
        <WordForm urlKey={props.urlKey}/>
      </div>
    )
  }
}