import React, { useState, useEffect } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
import { useAppDispatch } from '../../app/hooks'
import { Letter } from '../../types/_base'
import { 
  appendLetterToGuess, 
  removeLetterFromGuess 
} from '../../reducers/gamePrivateSlice'

const buttSty = {
  padding: '10px 14px',
  margin: '0 2px 4px',
  border: 0,
  borderRadius: '4px',
	backgroundColor: '#dadada',
	// textTransform: 'uppercase',
}

const LetterKey = (props: { letter: string }) => {
	const dispatch = useAppDispatch()
	const onClick = (e: any) => {
    e.preventDefault();
    console.log(`LetterKey onClick: ${props.letter}`);
    if (props.letter === 'DEL') {
      dispatch(removeLetterFromGuess({}))
    } else {
      dispatch(appendLetterToGuess(props.letter))
    }
	}

	return (
		<button 
			type="button"
			className='uppercase' 
			style={buttSty} 
			onClick={onClick} 
		>
      {props.letter}
    </button>
	)
}

export const Keyboard = () => {
	// const keyOrder = [
	// 	[ 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p' ],
	// 	[ 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l' ],
	// 	[ 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'delete' ],
	// ]
	const keyOrder = [
		[ 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p' ],
		[ 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l' ],
		[ 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'DEL' ],
	]
	
	return (
		<div>
			{keyOrder.map(
				(row, i) => {
					return(
						<div key={`keyboard-row-${i}`}>

              {row.map(
                (letter, i) => {
                  return(
                    <LetterKey key={letter} letter={letter} />
                  )
                }
              )}

						</div>
					)
				}
			)}
		</div>
	)
}

export default Keyboard