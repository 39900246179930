import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import authMiddleware from '../middlewares/authMiddleware';
import { appendGameChatMessages } from '../reducers/chatSlice';

import { MessageListServerResponse, ApiError } from '../types/dataTransfer';

// Set up base query with auth middleware
const messageApiEndpoint = `${process.env.REACT_APP_API_BASE_URL}/v1/messages`;
const baseQuery = fetchBaseQuery({ baseUrl: messageApiEndpoint});
const baseQueryWithAuth = authMiddleware(baseQuery);

export const messageApi = createApi({
  reducerPath: 'messageApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    fetchGameSessionMessages: builder.query<MessageListServerResponse, string>({
      query: (gameSessionId) => ({ // Query needs to be this more complex object to work with the middleware
        url: `?game_session_id=${gameSessionId}`,
        method: 'GET',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data: { messages }} = await queryFulfilled;
          console.log("messages:", messages);
          dispatch(appendGameChatMessages({ 
            gameSessionId: arg,
            messages,
           }));
        } catch (error) {
          // Handle error
          console.log("error", error);
        }
      }
    }),
    // createGame: builder.mutation<GameSessionServerResponse, null>({
    //   query: () => ({
    //     url: ``,
    //     method: 'POST',
    //     cache: "no-cache",
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data: { id, urlKey }} = await queryFulfilled;
    //       console.log("gameSessionData", {id, urlKey});
    //       dispatch(setNextGame({id, urlKey}));
    //     } catch (error) {
    //       // Handle error
    //     }
    //   },
    // }),
  }),
});


export const { 
  // useGetQuery, 
  useFetchGameSessionMessagesQuery, 
} = messageApi 