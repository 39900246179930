import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'

import { gameSessionApi } from '../services/gameSessionService'; 
import { messageApi } from '../services/messageService';
import gamePrivateReducer from '../reducers/gamePrivateSlice';
import gameSharedReducer from '../reducers/gameSharedSlice';
import chatReducer from '../reducers/chatSlice';

export const store = configureStore({
  reducer: {
    // Reducers generated by RTK Query 
    [gameSessionApi.reducerPath]: gameSessionApi.reducer,
    [messageApi.reducerPath]:     messageApi.reducer,

    // Standard reducers
    gamePrivate:  gamePrivateReducer,
    gameShared:   gameSharedReducer,
    chat:         chatReducer,
  },
  // Api middleware enabling caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(gameSessionApi.middleware)
      .concat(messageApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)