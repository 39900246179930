import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const sloganList = [
  "A maximally fun game",
  "A maximally fun game",
  "Not for the faint of guessing",
  "May thee best guesser win",
  "I saw the best minds of my generation destroyed by guessing",
  "Guess or die",
]

const SloganDisplay = () => {
  const useQuotes = false;
  
  let slogan: string = "";
  slogan = sloganList[Math.floor(Math.random() * sloganList.length)];
  
  useEffect(() => {
    slogan = sloganList[Math.floor(Math.random() * sloganList.length)];
  }, []);

  return (
    <div className="uppercase text-3xl mb-6" style={{letterSpacing: '0.5vw', lineHeight: 1}}>
      {useQuotes ? "&ldquo;" : ""}{slogan}{useQuotes ? "&rdquo;" : ""}
    </div>
  )
}

export default SloganDisplay; 