import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { RestEndpointState, Player, GamePhaseState } from '../types/_base';

import config from '../config';

export interface GamePrivateState {
  id: string | null;
  urlKey: string | null;
  participant: Player;
  letterPool: {
    availableLetters: string;
    validWords: [string] | [];
  }
  secretWord: {
    selected: string | null;
    set: string | null;
    serverMessage: string | null;
  }
  nextGuess: {
    word: string;
    serverMessage: string | null;
    submissionStatus: 'success' | 'error' | 'pending' | 'idle';
  }
  nextGame: GamePhaseState | null;
}

const initialState: GamePrivateState = {
  id: null,
  urlKey: null,
  participant: {
    id: null,
    displayName: null,
    participantToken: null,
    role: null,
  },
  letterPool: {
    availableLetters: '',
    validWords: [],
  },
  secretWord: {
    selected: null,
    set: null,
    serverMessage: null,
  },
  nextGuess: {
    word: '',
    serverMessage: null,
    submissionStatus: 'idle',
  },
  nextGame: null,
};

const gamePrivateSlice = createSlice({
  name: 'gamePrivate',
  initialState,
  reducers: {
    updatePrivateGameData: (state, action) => {
      console.log("updatePrivateGameData called with: ", action.payload)
      const gamePrivate = action.payload as GamePrivateState;
      const { participant, letterPool, secretWord, urlKey, id } = gamePrivate;

      if (id)     state.id = gamePrivate.id;
      if (urlKey) state.urlKey = gamePrivate.urlKey;
      
      if (letterPool) state.letterPool = letterPool;
      if (secretWord) state.secretWord = secretWord;
      
      if (Object.keys(participant).length > 0) {
        participant.id           && ( state.participant.id          = participant.id           );
        participant.displayName  && ( state.participant.displayName = participant.displayName  );
        participant.role         && ( state.participant.role        = participant.role         );
        if (participant.participantToken && urlKey) {
          localStorage.setItem('urlKey', urlKey); 
          localStorage.setItem('participantToken-' + urlKey, participant.participantToken); 
          state.participant.participantToken = participant.participantToken;
        }
      } 
    },
    setNextGame: (state, action) => {
      state.nextGame = action.payload;
    },
    // setParticipantData: (state, action) => {
    //   const participantData = action.payload;
    //   console.log("Setting participantData | participantData: ")
    //   console.log(participantData)
    //   if (Object.keys(participantData).length > 0) {
    //     const { participant } = participantData;
    //     participant.id           && ( state.participant.id          = participant.id           );
    //     participant.displayName  && ( state.participant.displayName = participant.displayName  );
    //     if (participant.participantToken && participantData.urlKey) {
    //       localStorage.setItem('urlKey', participantData.urlKey); 
    //       localStorage.setItem('participantToken-' + participantData.urlKey, participant.participantToken); 
    //       state.participant.participantToken = participant.participantToken;
    //     }
    //     if (participantData.secretWord) {
    //       state.secretWord.set = participantData.secretWord.set;
    //       state.secretWord.serverMessage = participantData.secretWord.serverMessage;
    //     }
    //     participantData.letterPool?.availableLetters && (state.letterPool.availableLetters = participantData.letterPool.availableLetters);
    //   } else {
    //     console.log("participantData is empty")
    //   }
    // },
    setSelectedWord: (state, action) => {
      state.secretWord.selected = action.payload;
    },
    shuffleAvailableLetters: (state) => {
      console.log("shuffling letters");
      const shuffledLetters = state.letterPool.availableLetters.split('').sort(() => Math.random() - 0.5).join('');
      state.letterPool.availableLetters = shuffledLetters;
    },
    // setAvailableLetters: (state, action) => {
    //   state.letterPool.availableLetters = action.payload;
    // },
    // setValidWords: (state, action) => {
    //   state.letterPool.validWords = action.payload;
    // },
    appendLetterToGuess: (state, action) => {
      const lastGuess = state.nextGuess.word ? state.nextGuess.word : '';
      if (lastGuess.length < 5) {
        state.nextGuess.word = `${lastGuess}${action.payload[0]}`;
      }
    },
    removeLetterFromGuess: (state, action) => {
      const { letter } = action.payload;

      if (letter) {
        // If a specific letter is provided, remove the first occurrence of that letter
        const index = state.nextGuess.word.indexOf(letter);
        if (index !== -1) { // Check if the letter exists
          // Remove the specific letter and update the state
          state.nextGuess.word = state.nextGuess.word.substring(0, index) + state.nextGuess.word.substring(index + 1);
        }
      } else {
        // If no specific letter is provided, remove the last letter
        const lastGuess = state.nextGuess.word ? state.nextGuess.word : '';
        if (lastGuess.length > 0) {
          state.nextGuess.word = lastGuess.slice(0, -1);
        }
      }
    },
    setSecretWordSelection: (state, action) => {
      state.secretWord.selected = action.payload;
    },
    appendLetterToSecretWordSelection: (state, action) => {
      const lastGuess = state.secretWord.selected? state.secretWord.selected : '';
      if (lastGuess.length < 5) {
        state.secretWord.selected = `${lastGuess}${action.payload[0]}`;
      }
    },
    removeLetterFromSecretWordSelection: (state, action) => {
      const { letter } = action.payload;
      const currentSelection = state.secretWord?.selected || '';

      if (letter) {
        // If a specific letter is provided, remove the first occurrence of that letter
        const index = currentSelection.indexOf(letter) || -1;
        if (index !== -1) { // Check if the letter exists
          // Remove the specific letter and update the state
          state.secretWord.selected = currentSelection.substring(0, index) + currentSelection.substring(index + 1);
        }
      } else {
        // If no specific letter is provided, remove the last letter
        if (currentSelection.length > 0) {
          state.secretWord.selected = currentSelection.slice(0, -1);
        }
      }
    },
    clearGuess: (state) => {
      console.log("clearing guess")
      state.nextGuess.word = '';
    },
    setNextGuess: (state, action) => {
      const { word, serverMessage } = action.payload;
      state.nextGuess.word = word;
      state.nextGuess.serverMessage = serverMessage;
    },
    setNextGuessSubmissionStatus: (state, action) => {
      state.nextGuess.submissionStatus = action.payload;
    }
  },
  extraReducers: (builder) => {},
})

export const {
  updatePrivateGameData,
  setNextGame,
  // setParticipantData, 
  setSelectedWord, 
  // setAvailableLetters,
  shuffleAvailableLetters, 
  // setValidWords,
  appendLetterToGuess,
  removeLetterFromGuess,
  appendLetterToSecretWordSelection,
  removeLetterFromSecretWordSelection,
  setSecretWordSelection,
  setNextGuess,
  clearGuess,
  setNextGuessSubmissionStatus,
  // mockAction 
} = gamePrivateSlice.actions

export default gamePrivateSlice.reducer