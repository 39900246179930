import React, { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import GameBoardPanel from '../../components/guessticle/GameBoard';

import { GameBoard, GuessData } from '../../types/_base';
import { getGuesses } from '../../reducers/gameSharedSlice';

export const PostGame = (props: { urlKey: string}) => {
  const dispatch = useAppDispatch();

  const boards: GameBoard[] = useAppSelector(state => state.gameShared.boards);
  const pastGuesses: string[] = useAppSelector(getGuesses) || [];

  const guessData: GuessData = {
    pastGuesses: pastGuesses,
    nextGuess: '' 
  };
  
  return (
    <div>
      <div className="flex overflow-x-scroll" style={{maxHeight: "66.7vh"}}>
        {boards.map((board, i) => { return <GameBoardPanel key={board.playerId} index={i} board={board} guesses={guessData} /> })}
      </div>
    </div>
  )
}

export default PostGame;