import React from 'react';
import { Outlet, ScrollRestoration } from "react-router-dom";

export default function FullScreenRoute() {
  return (
    <>
      <div id="main" className='p5 text-center vh-100 vw-100'>
        <Outlet />
      </div>
      <ScrollRestoration />
    </>
  );
}