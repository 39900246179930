import React, { useState, useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import {
  GameBoard,
  GuessData
} from '../../types/_base';
import {
  getGuesses,
  getCurrentPlayer,
 } from '../../reducers/gameSharedSlice';

import { clearGuess } from '../../reducers/gamePrivateSlice';

import { useSubmitGuessMutation } from '../../services/gameSessionService';

import GameBoardPanel from '../../components/guessticle/GameBoard';
import Keyboard from '../../components/guessticle/Keyboard';

export interface WordGuessingProps {
  urlKey: string;
  guesses: [];
	boards: GameBoard[];
}

const SubmitGuessButton = (props: { word: string, urlKey: string }) => {
  const [
    submitGuess,
    { isLoading: isUpdating, isError: isError, isSuccess: guessSuccessful, data: guessData, error: guessError },
  ] = useSubmitGuessMutation();

  console.log(isUpdating, isError, guessSuccessful, guessData, guessError)

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(state => state.gamePrivate.participant);
  const playerWhoseTurnItIs = useAppSelector(getCurrentPlayer)

  const word = props.word;
  const canSubmitGuess = word.length === 5 && playerWhoseTurnItIs?.id === currentUser.id;

  const handleClick = () => {
    if (canSubmitGuess) {
      // console.log("dispatching submitGuess"); 
      submitGuess({word: word, urlKey: props.urlKey});
      dispatch(clearGuess);
    }
  }

  return (
    <button className="btn btn-primary mb-8" type="button" onClick={handleClick} disabled={!canSubmitGuess}>
      Guess &ldquo;{props.word}&rdquo;
    </button>
  )
}

// export const WordGuessing = () => {
export const WordGuessing= (props: { urlKey: string}) => {
  const gameSharedState = useAppSelector(state => state.gameShared);
  const gamePrivateState = useAppSelector(state => state.gamePrivate);
  
  const { urlKey, boards } = gameSharedState;
  const { nextGuess } = gamePrivateState;
  const { word, serverMessage, submissionStatus } = nextGuess;

  const pastGuesses: string[] = useAppSelector(getGuesses) || [];
  
  const [msgCss, setMsgCss] = useState<string>('');
  let msgBgColor: string = '';
  // const apiState: string | null = useAppSelector(state => state.gamePrivate.apiState.postGuessSubmission)

  const guessData: GuessData = {
    pastGuesses: pastGuesses,
    nextGuess: word, 
  };

  // TODO - must be this player's turn
  const canSubmitGuess = word.length === 5;
 
  // TODO - do this through the RTK query hooks
  switch (submissionStatus) {
    case 'success':
      msgBgColor = '#e67c64';
      break;
    case 'error':
      msgBgColor = '#e67c64';
      break;
    default:
      msgBgColor = 'rgb(218, 214, 211)';
      break;
  }
  // if (apiState === 'success') {
  //   msgBgColor = '#e67c64';
  // } else {
  //   msgBgColor = '#e67c64';
  // } 
  
  return (
    <div className="flex flex-col justify-between h-full py-4">
      <div className="flex overflow-x-scroll grow items-center">
        {boards.map((board, i) => { return <GameBoardPanel key={board.playerId} index={i} board={board} guesses={guessData} /> })}
      </div>

      <div className='grow-0 flex flex-col items-center'>
        { serverMessage && <div className='max-w-xl px-4 py-2 mb-8 text-gray-0' style={{backgroundColor: msgBgColor}}>{serverMessage}</div>}
        { canSubmitGuess && <SubmitGuessButton word={word} urlKey={props.urlKey} /> }
        <Keyboard />
      </div>
    </div>
  )
}

export default WordGuessing;