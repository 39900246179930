import { web } from "webpack"

const origin = document.location.origin
const host = document.location.host

const isLocal = host.includes('localhost') || host.includes('lvh.me')
const isProduction = !isLocal 

const domain = isLocal ? 'lvh.me' : 'guessticle.com'

const websiteRoot = isLocal ? `http://${domain}:3000` : `https://${domain}`

const apiRoot   = isLocal ? `http://api.${domain}:3000/v0` : `https://api.${domain}/v0`

const websocketRoot = isLocal ? `ws://api.${domain}:3000/cable` : `wss://api.${domain}/cable`

const appName = "Guessticle"

export const config = {
  env: isProduction ? 'production' : 'development',
  origin: origin,
  apiRoot: apiRoot,
  websiteRoot: websiteRoot,
  websocketRoot: websocketRoot,
  appName: appName,
}

export default config