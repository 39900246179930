import React from 'react';
import { Outlet, ScrollRestoration, useParams } from "react-router-dom";

import GameSessionRightSidebar from '../features/guessticle/GameSessionRightSidebar';
import BasicHeader from '../components/guessticle/BasicHeader';

import { UrlKey } from '../types/_base';
import { useAppSelector } from '../app/hooks';

export default function FullScreenRoute() {
  const gameShared = useAppSelector(state => state.gameShared);
  const { id, urlKey } = gameShared; 
  
  return (
    <>
      <BasicHeader />
      <main className="m-0 p-0 text-center flex h-screen w-screen">
        <div className="grow overflow-scroll p-4 flex flex-col justify-center">
          <Outlet />
        </div>
        
        <div className="activity-panel-outer sm:h-18 md:h-screen w-full md:w-64 p-4 fixed md:static bottom-0">
          {id && urlKey && <GameSessionRightSidebar urlKey={urlKey} id={id} />}
        </div>
      </main>
      <ScrollRestoration />
    </>
  );
}