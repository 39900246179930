
export const popupBackgroundClasses = 'absolute inset-0 flex justify-center items-center bg-gray-50/25 z-20'
export const popupForegroundClasses = 'bg-gray-0 rounded-sm p-10 relative flex-col flex justify-center items-center z-30'
export const popupInnerClasses = 'overflow-y-scroll'
export const popupForegroundStyles = {
  minHeight: '25rem',
  maxHeight: '80vh',
  width: '25rem',
  zIndex: 30,
} 
