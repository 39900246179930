import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../app/store';

import { UUID, GameChatMessage, ChannelChatMessage } from '../types/_base';

export interface ChatState {
  gameSessions: {
    [key: UUID]: GameChatMessage[];
  };
  chatChannels: {
    [key: UUID]: ChannelChatMessage[];
  };
}

const initialState: ChatState = {
  gameSessions: {},
  chatChannels: {},
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // appendGameChatMessage: (state, action) => {
    //   const { gameSessionId, message } = action.payload;
    //   if (gameSessionId in state.gameSessions) {
    //     state.gameSessions[gameSessionId].push(message);
    //   } else {
    //     state.gameSessions[gameSessionId] = [message];
    //   }
    // },
    // appendGameChatMessages: (state, action) => {
    //   const { gameSessionId, messages } = action.payload;
    //   if (gameSessionId in state.gameSessions) {
    //     state.gameSessions[gameSessionId].push(...messages);
    //   } else {
    //     state.gameSessions[gameSessionId] = messages;
    //   }
    // }
    appendGameChatMessage: (state, action) => {
      const { gameSessionId, message } = action.payload;
      const existingMessageIndex = state.gameSessions[gameSessionId]?.findIndex(m => m.id === message.id);
      if (existingMessageIndex === -1) { // Message not found
        if (gameSessionId in state.gameSessions) {
          state.gameSessions[gameSessionId].push(message);
        } else {
          state.gameSessions[gameSessionId] = [message];
        }
      }
    },
    appendGameChatMessages: (state, action) => {
      const { gameSessionId, messages } = action.payload;
      if (!(gameSessionId in state.gameSessions)) {
        state.gameSessions[gameSessionId] = [];
      }
      messages.forEach((message: GameChatMessage) => {
        const existingMessageIndex = state.gameSessions[gameSessionId].findIndex(m => m.id === message.id);
        if (existingMessageIndex === -1) { // Message not found
          state.gameSessions[gameSessionId].push(message);
        }
      });
    }
  },
  extraReducers: (builder) => {},
})

export const getChatMessagesForGameSession = (state: RootState, gameSessionId: string) => {
  const availableMessages = state.chat.gameSessions[gameSessionId] || [];
  // const chatMessagesOrderedByCreatedAt = availableMessages.sort((a: GameChatMessage, b: GameChatMessage) => { return a.createdAt - b.createdAt });
  // return(chatMessagesOrderedByCreatedAt);
  return(availableMessages);
}

export const {
  appendGameChatMessage,
  appendGameChatMessages,
} = chatSlice.actions

export default chatSlice.reducer